<template>
  <CModal id="UserModel" class="ActionModel" :show.sync="Toggle" :centered="true" size="xl" :closeOnBackdrop="!Submit"
          @update:show="HandleHideModal">
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">
            <template v-if="Action === 'Update'">{{ $t('Organization/Member.UpdateUser') }}</template>
            <template v-if="Action === 'Create'">{{ $t('Organization/Member.CreateUser') }}</template>
            <template v-if="Action === 'Read'">{{ $t('Organization/Member.ReadUser') }}</template>
          </h5>
            <CButtonClose @click="HandleHideModal()"/>
        </header>
      </template>
      <CTabs :active-tab="0" :key="User.uid" fade addTabsWrapperClasses="tab-info">
        <CTab :title="$t('Organization/Member.Config')">
          <CRow class="mt-3">
            <CCol lg="6">
              <CInput v-if="Action === 'Update'|| Action === 'Read'" horizontal v-model="User.uid" :label="$t('Organization.Uid')"
                      disabled/>
              <CInput horizontal v-model="User.email" :label="$t('Organization.Email')"
                      :disabled="(Action === 'Update' || Action === 'Read')"/>
              <CInput horizontal v-model="User.displayName" :label="$t('Organization.Name')"
                      :disabled="Action === 'Read'"/>
              <CInput horizontal v-model="User.photoURL" :label="$t('Organization.Avatar')"
                      :disabled="Action === 'Read'"/>
            </CCol>
            <CCol lg="6">
              <CInput v-if="Action === 'Update'|| Action === 'Read'" horizontal v-model="UserData.ClientID" :label="$t('Organization.ClientID')"
                      disabled/>
              <CInput horizontal :type="(PasswordVisible ? 'text' : 'password')" v-model="User.password"
                      :label="$t('Organization.Password')" :disabled="Action === 'Read'">
                <template #append>
                  <CButton color="light" @click="PasswordVisible = !PasswordVisible">
                    <CIcon class="my-0" :name="(PasswordVisible ? 'cil-lock-unlocked' : 'cil-lock-locked')" size="sm"/>
                  </CButton>
                </template>
              </CInput>
              <CRow v-if="Action === 'Update'" form class="form-group">
                <CCol tag="label" sm="3" class="col-form-label">
                  {{ $t('Organization.EmailVerified') }}
                </CCol>
                <CCol sm="9" class="pt-2">
                  <CInputRadioGroup v-model="User.emailVerified" size="sm" :options="[{value: true, label: $t('Organization.Verified')},{value: false, label: $t('Organization.Unverified')}]" :checked.sync="User.emailVerified+''" :custom="true" inline/>
                </CCol>
              </CRow>
              <CRow v-if="Action === 'Update'" form class="form-group">
                <CCol tag="label" sm="3" class="col-form-label">
                  {{ $t('Organization.Status') }}
                </CCol>
                <CCol sm="9" class="pt-2">
                  <CInputRadioGroup v-model="User.disabled" size="sm" :options="[{value: false, label: $t('Organization.Enable')}, {value: true, label: $t('Organization.Disable')}]" :checked.sync="User.disabled+''" :custom="true" inline/>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CTab>
        <CTab :title="$t('Organization/Member.Info')">
          <CRow class="mt-3">
            <CCol lg="6">
              <CRow form class="form-group">
                <CCol tag="label" sm="3" class="col-form-label">
                  {{ $t('Organization.Class') }}
                </CCol>
                <CCol sm="9">
                  <CSelect class="mb-0" :options="ClassOptions" v-model="UserData.Class" :value.sync="UserData.Class" :placeholder="$t('Global.PleaseSelect')" :disabled="Action === 'Read'"/>
                </CCol>
              </CRow>
              <CRow form class="form-group">
                <CCol tag="label" sm="3" class="col-form-label">
                  {{ $t('Organization.Sex') }}
                </CCol>
                <CCol sm="9">
                  <CSelect class="mb-0" :options="SexOptions" v-model="UserData.Sex" :value.sync="UserData.Sex" :placeholder="$t('Global.PleaseSelect')" :disabled="Action === 'Read'"/>
                </CCol>
              </CRow>
              <CRow form class="form-group">
                <CCol tag="label" sm="3" class="col-form-label">
                  {{ $t('Organization.Birthday') }}
                </CCol>
                <CCol sm="9">
                  <v-date-picker v-model="UserData.Birthday">
                    <template v-slot="{ inputValue, inputEvents }">
                      <CInput placeholder="YYYY/mm/dd" :value="inputValue" v-on="inputEvents" class="mb-0" :disabled="Action === 'Read'">
                        <template #append-content>
                          <CIcon name="cil-calendar"/>
                        </template>
                      </CInput>
                    </template>
                  </v-date-picker>
                </CCol>
              </CRow>
              <CInput horizontal v-model="UserData.Phone" :label="$t('Organization.Phone')" @blur="CheckPhone('Phone')" :disabled="Action === 'Read'">
                <template #prepend-content>
                  <CIcon class="mr-1" :name="'cif-' + (typeof UserData.Country === 'string'? UserData.Country.toLowerCase() : 'tw')" /> {{ $t('Global.CountryCallPrefixOptions.' + (UserData.Country || 'TW')) }}
                </template>
              </CInput>
              <CInput horizontal v-model="UserData.Mobile" :label="$t('Organization.Mobile')" @blur="CheckPhone('Mobile')" :disabled="Action === 'Read'">
                <template #prepend-content>
                  <CIcon class="mr-1" :name="'cif-' + (typeof UserData.Country === 'string'? UserData.Country.toLowerCase() : 'tw')" /> {{ $t('Global.CountryCallPrefixOptions.' + (UserData.Country || 'TW')) }}
                </template>
              </CInput>
              <CRow form class="form-group">
                <CCol tag="label" sm="3" class="col-form-label">
                  {{ $t('Organization.Tags') }}
                </CCol>
                <CCol sm="9">
                  <multiselect
                    v-if="Action !== 'Read'"
                    v-model="UserData.Tags"
                    :tag-placeholder="$t('Global.EnterToAdd')"
                    :placeholder="$t('Global.PleaseKeyIn')"
                    :deselectLabel="$t('Global.DeselectLabel')"
                    :selectedLabel="$t('Global.SelectedLabel')"
                    :options="Tags"
                    :multiple="true"
                    :taggable="true"
                    @tag="(prop) => UserData.Tags.push(prop)">
                    <template #noOptions>
                      {{ $t('Global.NoOptions') }}
                    </template>
                  </multiselect>
                  <template v-else>
                    <CBadge v-for="Tag in UserData.Tags" :key="Tag" color="info" class="mr-1">{{ Tag }}</CBadge>
                  </template>
                </CCol>
              </CRow>
              <CTextarea horizontal v-model="UserData.Note" row="3" :label="$t('Organization.Note')" :disabled="Action === 'Read'"/>
            </CCol>
            <CCol lg="6">
              <CRow form class="form-group">
                <CCol tag="label" sm="3" class="col-form-label">
                  {{ $t('Organization.Country') }}
                </CCol>
                <CCol sm="9">
                  <CSelect class="mb-0" :options="CountryOptions" v-model="UserData.Country" :value.sync="UserData.Country" :placeholder="$t('Global.PleaseSelect')" :disabled="Action === 'Read'"/>
                </CCol>
              </CRow>
              <template v-if="(UserData.Country === 'TW')">
                <CSelect horizontal :label="$t('Organization.City')" :options="City" v-model="UserData.City" :value.sync="UserData.City" :placeholder="$t('Global.PleaseSelect')" :disabled="Action === 'Read'"/>
                <CSelect horizontal :label="$t('Organization.Area')" :options="Area" v-model="UserData.Area" :value.sync="UserData.Area" @change="SetZip()" :placeholder="$t('Global.PleaseSelect')" :disabled="Action === 'Read'"/>
              </template>
              <template v-else>
                <CInput horizontal v-model="UserData.City" :label="$t('Organization.City')" :disabled="Action === 'Read'"/>
                <CInput horizontal v-model="UserData.Area" :label="$t('Organization.Area')" :disabled="Action === 'Read'"/>
              </template>
              <CInput horizontal v-model="UserData.ZipCode" :label="$t('Organization.ZipCode')" :disabled="(UserData.Country === 'TW') || Action === 'Read'"/>
              <CInput horizontal v-model="UserData.Address" :label="$t('Organization.Address')" :disabled="Action === 'Read'"/>
              <CRow form class="form-group">
                <CCol tag="label" sm="3" class="col-form-label">
                  {{ $t('Organization.FacebookOpenID') }}
                </CCol>
                <CCol sm="9" class="col-form-label">
                  <span class="text-info">{{ UserData.FacebookOpenID || $t('Organization.Unbinding') }}</span>
                </CCol>
              </CRow>
              <CRow form class="form-group">
                <CCol tag="label" sm="3" class="col-form-label">
                  {{ $t('Organization.LineOpenID') }}
                </CCol>
                <CCol sm="9" class="col-form-label">
                  <span class="text-success">{{ UserData.LineOpenID || $t('Organization.Unbinding') }}</span>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CTab>
        <CTab class="d-none" :title="$t('Organization/Member.Other')">
          <CRow class="mt-3">
            <CCol lg="6">
              <CInput v-if="false" horizontal plaintext v-model="UserData.Level" :label="$t('Organization.Level')"/>
              <CInput v-if="false" horizontal v-model="UserData.CommissionPercentage" :label="$t('Organization.CommissionPercentage')">
                <template #append-content>
                  <span>%</span>
                </template>
              </CInput>
              <CRow form class="form-group d-none">
                <CCol tag="label" sm="3" class="col-form-label">
                  {{ $t('Organization.Groups') }}
                </CCol>
                <CCol sm="9">
                  <multiselect
                      v-model="UserData.Groups"
                      :options="MemberGroup"
                      :optionHeight="34"
                      label="label"
                      track-by="value"
                      :placeholder="$t('Global.PleaseSelect')"
                      :selectLabel="$t('Global.SelectLabel')"
                      :selectedLabel="$t('Global.SelectedLabel')"
                      :deselectLabel="$t('Global.DeselectLabel')"
                      :multiple="true"
                      :disabled="Action === 'Read'"
                  >
                    <template #noOptions>
                      {{ $t('Global.NoOptions') }}
                    </template>
                  </multiselect>
                </CCol>
              </CRow>
            </CCol>
            <CCol lg="6">
            </CCol>
          </CRow>
        </CTab>
        <CTab :title="$t('Organization/Member.OrderHistory')">
          <CDataTable
            id="BonusRecordList"
            addTableClasses="small text-nowrap"
            :items="OrderHistory"
            :fields="OrderHistoryField"
            :loading="Loading"
            :noItemsView="{noResults: $t('Global.NoResults'),noItems: $t('Global.NoItems')}"
            responsive
            hover
            striped
          >
            <template #OrderNum="{item}">
              <td>
                <a :href="`/order/detail/${item.OrderNum}`" target="_blank">{{item.OrderNum}}</a>
              </td>
            </template>
            <template #Origin="{item}">
              <td>
                {{ $t('Order.OriginOptions.' + item.OriginType) }}<span v-if="item.OriginType !== 'Default'">({{ item.OriginPage ? item.OriginPage : '' }})</span>
              </td>
            </template>
            <template #TotalAmount="{item}">
              <td>
                {{item.TotalAmount}}
              </td>
            </template>
            <template #RealAmount="{item}">
              <td>
                {{item.RealAmount}}
              </td>
            </template>
            <template #PaymentStatus="{item}">
              <td>
              <CBadge :color="StatusClassOption[item.Status.Payment]">
                {{ $t('Order.PaymentStatusList.' + item.Status.Payment) }}
              </CBadge>
              </td>
            </template>
            <template #LogisticsStatus="{item}">
              <td>
              <CBadge :color="StatusClassOption[item.Status.Logistics]">
                {{ $t('Order.LogisticsStatusList.' + item.Status.Logistics) }}
              </CBadge>
              </td>
            </template>
            <template #OrderStatus="{item}">
              <td>
              <CBadge :color="StatusClassOption[item.Status.Order]">
                {{ $t('Order.OrderStatusList.' + item.Status.Order) }}
              </CBadge>
              </td>
            </template>
            <template #CreateTime="{item}">
              <td>
                {{ $dayjs(item.CreateTime).format('YYYY-MM-DD HH:mm:ss') }}
              </td>
            </template>
          </CDataTable>
          <CCardFooter class="pb-0">
            <CPagination :activePage.sync="OrderPagination.Page" :pages.sync="OrderPagination.TotalPages"
              @update:activePage="ChangeOrderPage()"/>
          </CCardFooter>
        </CTab>
        <CTab :title="$t('Offer.Taxonomy.DiscountTicket')">
          <CDataTable
            id="DiscountTicket"
            addTableClasses="small text-nowrap"
            :items="DiscountTicket"
            :fields="DiscountTicketField"
            :loading="Loading"
            :noItemsView="{noResults: $t('Global.NoResults'),noItems: $t('Global.NoItems')}"
            responsive
            hover
            striped
          >
            <template #TicketName="{item}">
              <td>
                <a :href="`/offer/discountTicket/detail/${item._id}`" target="_blank">{{item.Name}}</a>
              </td>
            </template>
            <template #TicketSlug="{item}">
              <td>
                <span>{{ item.Slug }}</span>
              </td>
            </template>
            <template #Status="{item}">
              <td>
                <CBadge :color="DiscountTicketUsedStatusOptions[item.Status]">
                  {{ $t('Organization/Member.DiscountTicketUsedStatusOptions.' + item.Status) }}
                </CBadge>
              </td>
            </template>
            <template #TimeRange="{item}">
              <td>
                {{ $t('Offer.StartTime') }}: {{ $dayjs(item.StartTime._seconds * 1000).format('YYYY-MM-DD HH:mm:ss') }}<br>
                {{ $t('Offer.EndTime') }}: {{ $dayjs(item.EndTime._seconds * 1000).format('YYYY-MM-DD HH:mm:ss') }}
              </td>
            </template>
            <template #SendTime="{item}">
              <td v-if="item.CreateTime">
                {{ $dayjs(item.CreateTime._seconds * 1000).format('YYYY-MM-DD HH:mm:ss') }}
              </td>
            </template>
          </CDataTable>
        </CTab>
        <CTab :title="$t('Organization/Member.LevelChangeLog')">
          <CDataTable
            id="BonusRecordList"
            addTableClasses="small text-nowrap"
            :items="LevelChangeLog"
            :fields="LevelChangeLogField"
            :loading="Loading"
            :noItemsView="{noResults: $t('Global.NoResults'),noItems: $t('Global.NoItems')}"
            responsive
            hover
            striped
          >
            <template #CreateTime="{item}">
              <td>
                {{ $dayjs(item.CreateTime._seconds * 1000).format('YYYY-MM-DD HH:mm:ss') }}
              </td>
            </template>
            <template #FullAmount="{item}">
              <td>
                ${{ item.FullAmount }}
              </td>
            </template>
            <template #CurrentTotalAmount="{item}">
              <td>
                ${{ item.CurrentTotalAmount }}
              </td>
            </template>
            <template #GoldDiscount="{item}">
              <td>
                {{ item.GoldDiscount }}%
              </td>
            </template>
            <template #ShopDiscount="{item}">
              <td>
                {{ item.ShopDiscount }}%
              </td>
            </template>
          </CDataTable>
        </CTab>
        <CTab :title="$t('Organization/Member.ProviderLinking')">
          <template v-if="UserData.SocialProviders && UserData.SocialProviders.length > 0">
            <CCard v-for="(Item, Index) in UserData.SocialProviders" :key="Index">
              <CCardBody class="d-flex justify-content-between">
                <div>
                  <h4 class="text-success font-weight-bold">
                    {{ Item.Provider }}
                  </h4>
                  <div class="mt-4">
                    <CMedia :asideImageProps="{src: Item.Cover, class: 'rounded-circle'}">
                      <h5 class="mt-0">{{ Item.Name }}</h5>
                      <p class="mb-0">
                        {{ $t('Organization/Member.SocialProviderID') }}: {{ Item.Uid }}<br>
                        {{ $t('Organization/Member.StatusMessage') }}: {{ Item.StatusMessage || '沒有狀態' }}
                      </p>
                    </CMedia>
                  </div>
                </div>
              </CCardBody>
              <CElementCover
                v-if="(Submit === true)"
                :boundaries="[{ sides: ['center', 'center'], query: '#DeleteSubmit' }]"
                :opacity="0.8"
              >
                <CSpinner size="sm" color="primary"/>
              </CElementCover>
            </CCard>
          </template>
        </CTab>
      </CTabs>
      <template #footer-wrapper>
        <CElementCover
            v-if="(Submit === true) || (Loading === true)"
            :boundaries="[{ sides: ['center', 'center'], query: '#CreateSubmit' }]"
            :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="HandleHideModal()" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton v-if="Action !== 'Read'" id="CreateSubmit" @click="UserAction(Action)" color="danger" >
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
  </CModal>
</template>

<script>
import District from '@/assets/json/Districts.json'

export default {
  name: 'UserModel',
  props: ['Toggle', 'UserID', 'UserType', 'Tags'],
  components: {
    Multiselect: () => import('@/plugins/mutiselect')
  },
  data() {
    return {
      Submit: false,
      Loading: false,
      Title: '',
      Content: '',
      CurrentMemberList: [],
      User: {},
      UserData: {
        Tags: [],
        Groups: []
      },
      PasswordVisible: false,
      Action: '',
      District,
      MemberGroup: [],
      DiscountTicket:[],
      MemberCart:[],
      LevelChangeLog: [],
      OrderHistory: [],
      StatusClassOption: {
        'P': 'dark',
        'R': 'light',
        'RF': 'light',
        'U': 'warning',
        'C': 'secondary',
        'F': 'danger',
        'N': 'danger',
        'X': 'danger',
        'Y': 'success',
        'H': 'info',
        'S': 'primary',
        'O': 'info',
        'A': 'primary',
      },
      OrderPagination: {
        Page: 1,
        PerPage: 10,
        TotalItems: 0,
        TotalPages: 0
      },
      DiscountTicketUsedStatusOptions: {
        Used: 'info',
        Unused: 'warning',
        Expired: 'danger'
      }
    }
  },
  computed: {
    SexOptions() {
      return [
        { value: 'M', label: this.$t('Organization.SexOptions.M') },
        { value: 'F', label: this.$t('Organization.SexOptions.F') },
      ]
    },
    ClassOptions() {
      return Object.keys(this.$t('Organization.ClassOptions')).map(option => {
        return {
          value: option,
          label: this.$t('Organization.ClassOptions')[option]
        }
      })
    },
    CountryOptions () {
      return Object.keys(this.$t('Logistics.CountryOptions')).map(option => {
        return {
          value: option,
          label: this.$t('Logistics.CountryOptions')[option]
        }
      })
    },
    PerPageOption() {
      return Object.keys(this.$t('Organization/Member.PerPageOption')).map(option => {
        return {
          value: option,
          label: this.$t('Organization/Member.PerPageOption')[option]
        }
      })
    },
    City () {
      return this.District.map(Data => Data.name)
    },
    Area () {
      const ChooseCityData = this.District.filter(Data => Data.name === this.UserData.City) || []
      if (ChooseCityData.length > 0) {
        return ChooseCityData[0].districts.map(Area => Area.name)
      } else {
        return []
      }
    },
    Field() {
      const DefaultField = [
        { key: 'Checkbox', label: '' },
        { key: 'Action', label: '' },
        { key: 'Name', label: this.$t('Organization.Name') },
        { key: 'Contact', label: this.$t('Organization.Contact') },
        { key: 'creationTime', label: this.$t('Organization.CreateTime') },
        { key: 'Class', label: this.$t('Organization.Class') },
        // { key: 'lastSignInTime', label: this.$t('Organization.LastLoginTime') }
      ]
      if (this.Features.includes('MemberLevel')) {
        DefaultField.push({
          key: 'Level',
          label: this.$t('Organization/Member.Level')
        })
      }
      if (this.Features.includes('Bonus')) {
        DefaultField.push({
          key: 'Bonus',
          label: this.$t('Organization/Member.Bonus')
        })
      }
      DefaultField.push(
        { key: 'disabled', label: this.$t('Organization.Status') },
        { key: 'emailVerified', label: this.$t('Organization.EmailVerified') }
      )
      return DefaultField
    },
    LevelChangeLogField() {
      return [
        { key: 'CreateTime', label: '變更時間' },
        { key: 'Name', label: '等級名稱' },
        { key: 'FullAmount', label: '當前升級條件' },
        { key: 'GoldDiscount', label: '當前獲得購物金比率' },
        { key: 'ShopDiscount', label: '當前獲得折扣' },
        { key: 'CurrentTotalAmount', label: '當前累計消費金額' },
      ]
    },
    OrderHistoryField() {
      return [
        { key: 'OrderNum', label: '訂單編號' },
        { key: 'Origin', label: '結帳來源頁面' },
        { key: 'TotalAmount', label: this.$t('Organization/Member.TotalOrderAmount') },
        { key: 'RealAmount', label: this.$t('Organization/Member.TotalRealAmount') },
        { key: 'PaymentStatus', label: '付款狀態' },
        { key: 'LogisticsStatus', label: '配送狀態' },
        { key: 'OrderStatus', label: '訂單狀態' },
        { key: 'CreateTime', label: '建立時間' },
      ]
    },
    DiscountTicketField(){
      return [
        { key: 'TicketName', label: '折價券名稱' },
        { key: 'TicketSlug', label: '折價券代稱' },
        { key: 'Status', label: '使用狀態' },
        { key: 'TimeRange', label: '可使用區間' },
        { key: 'SendTime', label: '發送時間' }
      ]
    },
    MemberCartField(){
      return [
        { key: 'ProdName', label: '商品名稱' },
        { key: 'ProdNo', label: '商品編號' },
        { key: 'ProdPrice', label: '商品價格' },
        { key: 'ProdQuantity', label: '商品數量' },
        { key: 'ProdSum', label: '商品小計' }
      ]
    }
  },
  mounted() {
    this.Action = this.UserType
    this.GetUser(this.UserID)
  },
  methods: {
    HandleHideModal() {
      this.$emit('update:Toggle', false)
      this.$emit('update:ChooseUsers', [])
    },
    GetUser(userId){
      this.Loading = true

      const UserAPI = this.$store.dispatch('InnerRequest', {
        url: '/user/get',
        method: 'post',
        data: {
          userId
        }
      }).then(({data}) => {
        this.Loading = false
        this.User = data.user
        this.UserData = {
          ...data.userData,
          FacebookOpenID: data.userData.OpenID.Facebook,
          LineOpenID: data.userData.OpenID.Line,
          Phone: data.userData.Phone.replace(this.$t('Global.CountryCallPrefixOptions.' + (data.userData.Country || 'TW')), ''),
          Mobile: data.userData.Mobile.replace(this.$t('Global.CountryCallPrefixOptions.' + (data.userData.Country || 'TW')), '')
        }
        this.LevelChangeLog = data.levelChangeLog
        this.DiscountTicket = data.discountTicket
      }).catch((err) => {
        throw err
      })
      const OrderAPI =  this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/order/list2',
          Data: {
            UserID: userId,
            Taxonomy: 'ProductOrder',
            Page: this.OrderPagination.Page,
            PerPage: this.OrderPagination.PerPage,
            Order: 'nto',
            SearchAllTimeRange: true
          }
        }
      }).then(({data}) => {
        this.OrderHistory = data.Data.map(item => {
          item.OriginType = item.Variable.CartType ? item.Variable.CartType.split('-')[0] : 'Default'
          item.OriginPageSlug = item.Variable.CartType !== 'Default' ? (item.Variable.CartType || '').replace('SinglePage-', '') : '--'
          item.OriginPage = item.Variable.CartTypeName || (item.Variable.CartType || '').replace('SinglePage-', '')
          return item
        })
        this.OrderPagination = {
          Page: data.Pagination.Page,
          PerPage: data.Pagination.PerPage.toString(),
          TotalItems: data.Pagination.TotalItems,
          TotalPages: data.Pagination.TotalPages
        }
      }).catch((err) => {
        throw err
      })
      const CartAPI = this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/cart/get',
          Data: {
            UserID: userId || '0',
            VisitID: '0',
            CartType: 'Default'
          }
        }
      }).then(({data}) => {
        this.MemberCart = data.Data.map(item => {
          return{
            ...item,
            Amount: item.ProductInfo.Amount.Actual,
            Name: item.ProductInfo.Name,
            Cover: item.ProductInfo.Cover,
            SeriesNum: item.ProductInfo.SeriesNum
          }
        })
      }).catch((err) => {
        console.log(err)
        throw err
      })
      return Promise.all([
          UserAPI, OrderAPI, CartAPI
      ]).then(() => {
        this.Loading = false
        return true
      }).catch((err) => {
        console.log(err)
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + ': ' + err.msg.message || err.msg,
          type: (err.type ? err.type : 'error')
        })
      })
    },
    ChangeOrderPage(){
      return this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/order/list2',
          Data: {
            UserID: this.User.uid,
            Taxonomy: 'ProductOrder',
            Page: this.OrderPagination.Page,
            PerPage: this.OrderPagination.PerPage,
            Order: 'nto',
            SearchAllTimeRange: true
          }
        }
      }).then(({data}) => {
        this.OrderHistory = data.Data.map(item => {
          item.OriginType = item.Variable.CartType ? item.Variable.CartType.split('-')[0] : 'Default'
          item.OriginPageSlug = item.Variable.CartType !== 'Default' ? (item.Variable.CartType || '').replace('SinglePage-', '') : '--'
          item.OriginPage = item.Variable.CartTypeName || (item.Variable.CartType || '').replace('SinglePage-', '')
          return item
        })
        this.OrderPagination = {
          Page: data.Pagination.Page,
          PerPage: data.Pagination.PerPage.toString(),
          TotalItems: data.Pagination.TotalItems,
          TotalPages: data.Pagination.TotalPages
        }
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    SetZip () {
      this.UserData.ZipCode = parseInt(this.District.filter(Data => Data.name === this.UserData.City)[0].districts.filter(Area => Area.name === this.UserData.Area)[0].zip)
    },
    CheckPhone () {
      switch (this.UserData.Country) {
        case 'TW':
        default:
          this.UserData.Mobile = this.UserData.Mobile.replace(/^09/, '+8869')
          break
      }
    },
  }
}
</script>
